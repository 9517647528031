@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gradient-to-r from-purple-500 to-pink-400 dark:from-purple-900 dark:to-pink-800;
}

input:focus {
  @apply outline-none;
}

textarea:focus {
  @apply outline-none;
}

button:focus {
  @apply outline-none;
}

button, button:hover, li:hover, a:hover , li , a , *:hover, *
{
  -webkit-touch-callout:none;
  -webkit-text-size-adjust:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

/**:not(input):not(textarea),
::after,
::before {
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
}*/

html, body {
  -webkit-overflow-scrolling: touch;
  overflow-y:auto;
  overflow-x:hidden;
  -webkit-appearance: none;
}

textarea:not([resize="true"]) {
  resize: none !important;
}
